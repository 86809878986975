@tailwind components;

@layer components {
  .nano-date-picker {
    height: 40px;
    @apply w-full rounded-lg border-transparent bg-primary-dark-purple-5 text-base-l;
    &:hover {
      @apply border-primary-dark-purple-60;
    }

    input {
      @apply text-text-80 placeholder:text-base-l placeholder:text-text-60;
    }

    &.ant-picker-focused {
      @apply border-system-info-100 shadow-input-focus-shadow outline-0;
    }

    .ant-picker-clear {
      @apply bg-primary-dark-purple-5;
      &:hover {
        @apply text-primary-dark-purple-100;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .nano-date-picker-dropdown {
    .ant-picker-header {
      @apply border-0;
    }

    .ant-picker-header {
      .ant-picker-header-prev-btn {
      }

      .ant-picker-header-next-btn {
      }

      .ant-picker-header-super-prev-btn {
        @apply invisible opacity-0;
      }

      .ant-picker-header-super-next-btn {
        @apply invisible opacity-0;
      }
    }

    .ant-picker-content {
      thead {
        tr {
          th {
            @apply font-bold text-text-80;
          }
        }
      }
    }

    .ant-picker-cell {
      @apply p-0;
      @apply text-base-s;
      .ant-picker-cell-inner {
        width: 32px;
        height: 32px;
        @apply inline-flex items-center justify-center rounded-full bg-transparent text-primary-dark-purple-80;
      }

      &.ant-picker-cell-in-view.ant-picker-cell-today {
        .ant-picker-cell-inner {
          @apply font-bold text-secondary-purple;
          &:before {
            display: none;
          }
        }
      }

      &:hover {
        &:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(
            .ant-picker-cell-range-end
          ):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
          .ant-picker-cell-inner {
          @apply bg-primary-light-purple-40;
        }
      }

      &.ant-picker-cell-selected {
        .ant-picker-cell-inner {
          @apply bg-primary-light-purple-40 text-primary-dark-purple-80;
        }

        &:hover {
          .ant-picker-cell-inner {
            @apply bg-primary-light-purple-40 text-primary-dark-purple-80;
          }
        }
      }
    }
  }
}
