@tailwind components;

@layer components {
  .nano-dialog {
    .ant-modal {
      @apply p-0;
      .ant-modal-content {
        @apply relative p-0 pt-10;
      }
    }
  }
}
