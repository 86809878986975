@tailwind components;

@layer components {
  /* Base styles for switch */
  #root {
    .nano-switch {
      .ant-switch-inner {
        width: 42px;
        height: 24px;
        background-color: theme('colors.primary.light-purple.100');
        transition: background-color 0.2s ease-in-out;
      }
      .ant-switch-handle {
        top: 3px;
        inset-inline-start: 3px;
      }
      &:hover {
        background: none;
      }
    }
    .nano-switch.ant-switch-checked {
      background: none;
      .ant-switch-inner {
        background-color: theme('colors.secondary.purple');
      }
      .ant-switch-handle {
        inset-inline-start: calc(100% - 23px);
      }
    }
    .nano-switch.ant-switch-disabled {
      .ant-switch-inner {
        background-color: theme('colors.text.10');
      }
    }
  }
}
