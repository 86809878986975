@tailwind components;

@layer components {
  .nano-table {
    th,
    td {
      @apply py-2.5 text-text-80;
    }

    &.nano-table__accent-borders {
      td {
        @apply border-b-text-20;
      }
    }

    .ant-table-thead {
      th {
        @apply bg-transparent text-primary-dark-purple-100;

        .ant-table-column-sorters {
          @apply justify-normal;

          .ant-table-column-title {
            @apply mr-2 flex-none;
          }
        }
      }
    }

    .ant-table-row {
      @apply relative text-text-80;

      &:hover {
        td {
          @apply bg-primary-light-purple-10;
        }
      }

      &[class*='ant-table-row-level-']:not(.ant-table-row-level-0) {
        @apply bg-text-10;
      }

      .ant-table-cell {
        &.ant-table-column-sort {
          @apply bg-transparent;
        }
      }
    }

    .ant-table-row-expand-icon {
      height: 22px;
      width: 22px;
      @apply bg-primary-dark-purple-100 text-secondary-white;

      &:before {
        height: 2px;
        top: 9px;
      }

      &:after {
        width: 2px;
        inset-inline-start: 9px;
      }

      &.ant-table-row-expand-icon-collapsed {
        @apply border-primary-dark-purple-100 bg-transparent text-primary-dark-purple-100;

        &.ant-table-row-expand-icon-expanded {
          & + * {
            @apply font-bold text-primary-dark-purple-100;
          }
        }
      }
    }

    .ant-pagination {
      @apply justify-center;

      > .ant-pagination-item {
        a {
          @apply no-underline;
        }

        &.ant-pagination-item-active {
          @apply border-transparent bg-primary-dark-purple-100 hover:bg-secondary-purple;

          a {
            @apply text-secondary-white;
          }
        }
      }

      .ant-select {
        width: 68px;
      }

      .ant-select-selector {
        @apply cursor-pointer border-transparent;

        .ant-select-selection-search {
          @apply hidden;
        }
      }

      .ant-select-arrow {
        .anticon-search {
          @apply hidden;
        }
      }

      .ant-pagination-options {
        @apply -order-1 mr-6 rounded-xl bg-primary-dark-purple-10;

        .ant-select {
          .ant-select-selector {
            @apply rounded-xl bg-primary-dark-purple-10;
          }
        }
      }
    }

    &.nano-segments-table {
      .ant-table {
        @apply bg-transparent;
        .ant-table-container {
          .ant-table-content {
            .ant-table-thead {
              .ant-table-cell {
                @apply bg-transparent p-2;
                &:first-child {
                  @apply pl-9;
                }

                &:last-child {
                  @apply pr-9;
                }
              }
            }

            .ant-table-tbody {
              .ant-table-row {
                &:first-child {
                  td {
                    &:first-child {
                      border-top-left-radius: 20px;
                    }

                    &:last-child {
                      border-top-right-radius: 20px;
                    }
                  }
                }

                &:last-child {
                  td {
                    &:first-child {
                      border-bottom-left-radius: 20px;
                    }

                    &:last-child {
                      border-bottom-right-radius: 20px;
                    }
                  }
                }

                &:hover {
                  .segment-name-link {
                    @apply text-secondary-purple;
                  }

                  .ant-table-cell {
                    @apply bg-primary-light-purple-10;
                  }
                }

                .ant-table-cell {
                  @apply border-page-default bg-secondary-white px-2 py-5;
                  &:first-child {
                    @apply pl-9;
                  }

                  &:last-child {
                    @apply pr-9;
                  }
                }
              }
            }
          }
        }
      }

      &.nano-grouped-table {
        .ant-table {
          .ant-table-container {
            .ant-table-content {
              .ant-table-tbody {
                .ant-table-row-level-0 {
                  .ant-table-cell {
                    @apply bg-transparent;
                  }
                }

                tr[data-first='true'] {
                  td {
                    &:first-child {
                      border-top-left-radius: 20px !important;
                    }

                    &:last-child {
                      border-top-right-radius: 20px !important;
                    }
                  }
                }

                tr[data-last='true'] {
                  td {
                    &:first-child {
                      border-bottom-left-radius: 20px !important;
                    }

                    &:last-child {
                      border-bottom-right-radius: 20px !important;
                    }
                  }
                }
              }
            }
          }

          .ant-table-row-expand-icon {
            @apply hidden;
          }
        }
      }
    }

    &.nano-keyword-groups-table {
      .ant-table {
        @apply bg-transparent;
        .ant-table-container {
          .ant-table-content {
            .ant-table-thead {
              .ant-table-cell {
                @apply bg-transparent p-2;
                &:first-child {
                  @apply pl-9;
                }

                &:last-child {
                  @apply pr-9;
                }
              }
            }

            .ant-table-tbody {
              .ant-table-row {
                &:first-child {
                  td {
                    &:first-child {
                      border-top-left-radius: 20px;
                    }

                    &:last-child {
                      border-top-right-radius: 20px;
                    }
                  }
                }

                &:last-child {
                  td {
                    &:first-child {
                      border-bottom-left-radius: 20px;
                    }

                    &:last-child {
                      border-bottom-right-radius: 20px;
                    }
                  }
                }

                &:hover {
                  .segment-name-link {
                    @apply text-secondary-purple;
                  }

                  .ant-table-cell {
                    @apply bg-primary-light-purple-10;
                  }
                }

                .ant-table-cell {
                  @apply border-page-default bg-secondary-white px-2 py-5;
                  &:first-child {
                    @apply pl-9;
                  }

                  &:last-child {
                    @apply pr-9;
                  }
                }
              }
            }
          }
        }
      }
    }

    .ant-spin {
      .ant-spin-dot-item {
        @apply bg-primary-dark-purple-80;
      }
    }
  }
}
