@tailwind base;

@layer base {
  @keyframes scaleUp {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }

  @keyframes backgroundSlide {
    from {
      background-position: -100% -100%;
    }
    to {
      background-position: left;
    }
  }

  .scale-animation {
    animation: scaleUp 0.8s forwards;
    animation-delay: 0.2s;
    transform: scale(0);
  }

  .background-slide-animation {
    animation: backgroundSlide 1s forwards;
    animation-delay: 0.2s;
    background-position: -100% -100%;
  }
}
