@tailwind components;

@layer components {
  .nano-radio {
    .ant-radio-inner {
      width: 24px;
      height: 24px;
      border-color: theme('colors.primary.dark-purple.100');
    }

    > span {
      &:last-child {
        font-size: theme('fontSize.base-l');
        color: theme('colors.text.80');
      }
    }

    .ant-radio {
      &:after {
        display: none;
      }
    }

    &:hover {
      .ant-radio-inner {
        border-color: theme('colors.secondary.purple');
      }

      > span {
        &:last-child {
          color: theme('colors.text.100');
        }
      }

      .ant-radio.ant-radio-checked {
        .ant-radio-inner {
          border-color: theme('colors.secondary.purple');
        }
      }
    }

    .ant-radio.ant-radio-checked {
      .ant-radio-inner {
        background-color: transparent;
        border-color: theme('colors.primary.dark-purple.100');
        border-width: 6px;

        &:after {
          display: none;
        }
      }
    }

    .ant-radio.ant-radio-disabled {
      &:not(.ant-radio-checked) {
        .ant-radio-inner {
          border: none;
          background-color: theme('colors.text.10');
        }
      }
    }

    .ant-radio.ant-radio-checked.ant-radio-disabled {
      .ant-radio-inner {
        background-color: theme('colors.text.20');
        border-color: theme('colors.text.10');
      }
    }
  }

  .nano-radio.ant-radio-wrapper-disabled {
    > span {
      &:last-child {
        color: theme('colors.text.40');
      }
    }
  }
}

.ant-radio-button-wrapper-checked {
  border-color: #8c48bf !important;
  color: #8c48bf !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #8c48bf !important;
}

.ant-radio-button-wrapper:focus {
  border-color: #8c48bf;
  color: #8c48bf !important;
}

.ant-radio-button-wrapper:hover {
  color: #8c48bf !important;
}
