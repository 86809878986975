@tailwind components;

@layer components {
  @keyframes animate1 {
    0% {
      transform: translateY(11.5%);
      opacity: 0;
    }
    60% {
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes animate2 {
    0% {
      transform: translateX(11.5%);
      opacity: 0;
    }
    60% {
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes animate3 {
    0% {
      transform: translateX(-11.5%);
      opacity: 0;
    }
    60% {
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes animate4 {
    0% {
      transform: translateY(-11.5%);
      opacity: 0;
    }
    60% {
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .nano-loader {
    .nano-loader-circle-1 {
      transform: translateY(11.5%);
      opacity: 0;
      animation: animate2 5s infinite;
      animation-delay: 850ms;
    }

    .nano-loader-circle-2 {
      transform: translateX(11.5%);
      opacity: 0;
      animation: animate2 5s infinite;
      animation-delay: 700ms;
    }

    .nano-loader-circle-3 {
      transform: translateX(11.5%);
      opacity: 0;
      animation: animate2 5s infinite;
      animation-delay: 1300ms;
    }

    .nano-loader-circle-4 {
      transform: translateY(11.5%);
      opacity: 0;
      animation: animate1 5s infinite;
      animation-delay: 1050ms;
    }

    .nano-loader-circle-5 {
      transform: translateX(-11.5%);
      opacity: 0;
      animation: animate3 5s infinite;
      animation-delay: 650ms;
    }

    .nano-loader-circle-6 {
      transform: translateY(11.5%);
      opacity: 0;
      animation: animate1 5s infinite;
      animation-delay: 900ms;
    }

    .nano-loader-circle-7 {
      transform: translateY(11.5%);
      opacity: 0;
      animation: animate1 5s infinite;
      animation-delay: 950ms;
    }

    .nano-loader-circle-8 {
      transform: translateY(11.5%);
      opacity: 0;
      animation: animate1 5s infinite;
      animation-delay: 900ms;
    }

    .nano-loader-circle-9 {
      transform: translateX(11.5%);
      opacity: 0;
      animation: animate2 5s infinite;
      animation-delay: 400ms;
    }

    .nano-loader-circle-10 {
      transform: translateY(11.5%);
      opacity: 0;
      animation: animate1 5s infinite;
      animation-delay: 850ms;
    }

    .nano-loader-circle-11 {
      transform: translateY(11.5%);
      opacity: 0;
      animation: animate1 5s infinite;
      animation-delay: 1150ms;
    }

    .nano-loader-circle-12 {
      transform: translateX(-11.5%);
      opacity: 0;
      animation: animate3 5s infinite;
      animation-delay: 200ms;
    }

    .nano-loader-circle-13 {
      transform: translateX(-11.5%);
      opacity: 0;
      animation: animate3 5s infinite;
      animation-delay: 600ms;
    }

    .nano-loader-circle-14 {
      transform: translateX(-11.5%);
      opacity: 0;
      animation: animate3 5s infinite;
      animation-delay: 1100ms;
    }

    .nano-loader-circle-15 {
      transform: translateX(11.5%);
      opacity: 0;
      animation: animate2 5s infinite;
      animation-delay: 100ms;
    }

    .nano-loader-circle-16 {
      transform: translateX(11.5%);
      opacity: 0;
      animation: animate2 5s infinite;
      animation-delay: 500ms;
    }

    .nano-loader-circle-17 {
      transform: translateX(11.5%);
      opacity: 0;
      animation: animate2 5s infinite;
      animation-delay: 1200ms;
    }

    .nano-loader-circle-18 {
      transform: translateY(11.5%);
      opacity: 0;
      animation: animate1 5s infinite;
      animation-delay: 0ms;
    }

    .nano-loader-circle-19 {
      transform: translateX(-11.5%);
      opacity: 0;
      animation: animate3 5s infinite;
      animation-delay: 550ms;
    }

    .nano-loader-circle-20 {
      transform: translateY(-11.5%);
      opacity: 0;
      animation: animate4 5s infinite;
      animation-delay: 1150ms;
    }

    .nano-loader-circle-21 {
      transform: translateX(-11.5%);
      opacity: 0;
      animation: animate3 5s infinite;
      animation-delay: 450ms;
    }

    .nano-loader-circle-22 {
      transform: translateX(11.5%);
      opacity: 0;
      animation: animate2 5s infinite;
      animation-delay: 50ms;
    }

    .nano-loader-circle-23 {
      transform: translateX(11.5%);
      opacity: 0;
      animation: animate2 5s infinite;
      animation-delay: 150ms;
    }

    .nano-loader-circle-24 {
      transform: translateX(11.5%);
      opacity: 0;
      animation: animate2 5s infinite;
      animation-delay: 750ms;
    }

    .nano-loader-circle-25 {
      transform: translateY(-11.5%);
      opacity: 0;
      animation: animate4 5s infinite;
      animation-delay: 950ms;
    }

    .nano-loader-circle-26 {
      transform: translateX(-11.5%);
      opacity: 0;
      animation: animate3 5s infinite;
      animation-delay: 500ms;
    }

    .nano-loader-circle-27 {
      transform: translateX(-11.5%);
      opacity: 0;
      animation: animate3 5s infinite;
      animation-delay: 1000ms;
    }

    .nano-loader-circle-28 {
      transform: translateX(-11.5%);
      opacity: 0;
      animation: animate3 5s infinite;
      animation-delay: 250ms;
    }

    .nano-loader-circle-29 {
      transform: translateX(11.5%);
      opacity: 0;
      animation: animate2 5s infinite;
      animation-delay: 350ms;
    }

    .nano-loader-circle-30 {
      transform: translateX(11.5%);
      opacity: 0;
      animation: animate2 5s infinite;
      animation-delay: 1250ms;
    }

    .nano-loader-circle-31 {
      transform: translateY(-11.5%);
      opacity: 0;
      animation: animate4 5s infinite;
      animation-delay: 300ms;
    }

    .nano-loader-circle-32 {
      transform: translateY(-11.5%);
      opacity: 0;
      animation: animate4 5s infinite;
      animation-delay: 1050ms;
    }

    .nano-loader-circle-33 {
      transform: translateX(-11.5%);
      opacity: 0;
      animation: animate3 5s infinite;
      animation-delay: 500ms;
    }

    .nano-loader-circle-34 {
      transform: translateY(-11.5%);
      opacity: 0;
      animation: animate4 5s infinite;
      animation-delay: 850ms;
    }

    .nano-loader-circle-35 {
      transform: translateY(-11.5%);
      opacity: 0;
      animation: animate4 5s infinite;
      animation-delay: 500ms;
    }
  }
}
