@tailwind components;

@layer components {
  #root .nano-collapse-ctv {
    @apply divide-y border-0 bg-primary-dark-purple-5;

    .ant-collapse-item {
      @apply w-full border border-primary-dark-purple-10 first-of-type:rounded-t-lg first-of-type:border-b-0 last-of-type:border-b;

      .ant-collapse-expand-icon {
        @apply m-0 h-full p-0 duration-200;

        svg {
          @apply h-6 w-6;
        }
      }

      .ant-collapse-header {
        @apply h-20 w-full max-w-full gap-2 truncate p-4;
        .ant-collapse-header-text {
          @apply h-full w-full max-w-full text-base-l font-medium hover:text-secondary-purple;
        }
      }

      .ant-collapse-content {
        @apply border-t-0 bg-secondary-white p-0;
        .ant-collapse-content-box {
          @apply p-0;
        }
      }

      &.ant-collapse-item-active {
        .ant-collapse-header {
          @apply border-b-0;
        }

        .ant-collapse-header-text {
          @apply text-secondary-purple;
        }

        .ant-collapse-expand-icon {
          @apply rotate-180 duration-200;
        }
      }
    }
  }
}
