@tailwind components;

@layer component {
  .tour-current-step {
    box-shadow:
      inset 0 0 10px 1px rgba(0, 255, 250, 0.55),
      0 0 7px 1px rgba(0, 255, 250, 0.55) #{!important};
    @apply pointer-events-none border-primary-electric-cyan-40 bg-primary-electric-cyan-100/[.12] text-primary-electric-cyan-40 #{!important};
    @apply z-[50] #{!important};
  }

  .ant-tour-mask {
    @apply z-[50] #{!important};
  }

  .ant-tour {
    @apply z-[50] #{!important};
    .ant-tour-content {
      @apply ml-8 w-[400px] text-secondary-white;

      .ant-tour-inner {
        background: rgba(0, 0, 0, 0.24) #{!important};
        @apply rounded-2xl p-7 backdrop-blur-[24px] #{!important};

        .ant-tour-close {
          @apply right-7 top-7 #{!important};

          &:hover {
            background: rgba(255, 255, 255, 0.08) #{!important};
          }

          svg {
            @apply text-primary-electric-cyan-100;
          }
        }

        .ant-tour-header {
          @apply ml-14 pb-4 pt-0 #{!important};
          .ant-tour-title {
            @apply text-base-xl #{!important};
          }
        }

        .ant-tour-description {
          @apply ml-14;
        }

        .ant-tour-footer {
          @apply px-0 pb-0 pt-8 #{!important};

          .ant-tour-indicators {
            background: rgba(255, 255, 255, 0.08);
            @apply flex h-9 w-16 items-center justify-center rounded-b rounded-t text-text-40 #{!important};
          }

          .ant-tour-buttons {
            .ant-btn {
              @apply h-9 w-14 border-primary-electric-cyan-100 px-4 hover:bg-primary-electric-cyan-100 #{!important};

              svg {
                @apply text-primary-electric-cyan-100;
              }

              &:hover {
                svg {
                  @apply text-nano-dark-purple;
                }
              }
            }
          }
        }
      }
    }
  }
}
