.two-column-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-height: 400px;
  overflow-y: auto;
  margin-top: 7px;
  gap: 2rem;
  justify-items: center !important;
}

.two-column-list li {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  width: 90%;
  box-sizing: border-box;
}

.weight-input {
  background-color: white !important;
}
