@tailwind components;

@layer components {
  #root .nano-tag {
    @apply m-0 inline-flex max-w-full cursor-default items-center justify-between gap-x-1 whitespace-nowrap px-2 py-1 text-base text-primary-dark-purple-80 hover:text-primary-dark-purple-100;

    &.green {
      @apply bg-system-success-20 hover:bg-system-success-50;
    }

    &.red {
      @apply bg-system-error-20 hover:bg-system-error-30;
    }

    .ant-tag-close-icon {
      @apply m-0 text-inherit;
    }
  }
}
