@tailwind components;

@layer components {
  #root .nano-tree-view-collapse {
    @apply border-0 bg-transparent;

    .ant-collapse-item {
      @apply mb-5 w-full border-b-0 last-of-type:mb-0;

      &.ant-collapse-item-active {
        .ant-collapse-header {
          @apply rounded-b-none bg-primary-dark-purple-10;
        }
      }

      .ant-collapse-header {
        height: 56px;
        @apply flex items-center rounded-lg bg-primary-light-purple-10 px-4 py-2;

        .ant-collapse-header-text {
          @apply text-base-l font-medium;
        }
      }

      .ant-collapse-content {
        @apply rounded-b-lg border-none bg-primary-light-purple-10;
      }
    }
  }
}
