@tailwind components;

@layer components {
  .nano-tabs {
    .ant-tabs-nav {
      @apply mb-6;
    }

    .ant-tabs-nav-wrap {
      @apply flex-initial font-medium;
    }

    .ant-tabs-ink-bar,
    .ant-tabs-nav::before {
      @apply hidden;
    }

    .ant-tabs-tab + .ant-tabs-tab {
      @apply ml-1;
    }

    .ant-tabs-tab {
      @apply inline-flex min-w-[151px] justify-center rounded px-5 py-4 text-text-80 transition-all hover:bg-text-5;
    }

    .ant-tabs-tab:hover .ant-tabs-tab-btn {
      @apply text-primary-dark-purple-80;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
      @apply bg-text-5 bg-transparent hover:bg-text-10;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
    .ant-tabs-tab.ant-tabs-tab-active:hover .ant-tabs-tab-btn {
      @apply text-primary-dark-purple-100;
    }

    .ant-tabs-tab .ant-tabs-tab-btn {
      @apply leading-4;
    }

    .ant-tabs-ink-bar {
      @apply block bg-primary-dark-purple-100;
    }

    &.full-width {
      .ant-tabs-nav-wrap {
        @apply w-full;
        .ant-tabs-nav-list {
          @apply flex w-full;
          .ant-tabs-tab {
            @apply w-full;
          }
        }
      }
    }
    &.full-height {
      @apply h-full;
      .ant-tabs-content {
        @apply h-full;
        .ant-tabs-tabpane {
          @apply h-full;
        }
      }
    }
  }

  /*Graph tabs*/
  .nano-tabs.nano-tabs-with-chevron {
    .ant-tabs-nav-wrap {
      @apply flex-initial rounded-lg bg-transparent p-1 font-semibold;
    }

    .ant-tabs-tab {
      @apply min-w-min rounded-lg bg-transparent hover:bg-primary-light-purple-10;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
      @apply bg-text-5;
    }

    .ant-tabs-tab.ant-tabs-tab-disabled {
      @apply bg-transparent;
    }

    .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn,
    .ant-tabs-tab.ant-tabs-tab-disabled:hover,
    .ant-tabs-tab-btn {
      @apply text-text-40;
      .ant-tabs-tab .ant-tabs-tab-btn {
        @apply flex items-center gap-x-1 text-base text-text-80;
      }

      .ant-tabs-tab:hover .ant-tabs-tab-btn,
      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        @apply text-primary-dark-purple-100;
      }

      .ant-tabs-tab .ant-tabs-tab-btn svg {
        @apply h-4 w-4 text-inherit transition-all;
      }

      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn svg {
        @apply rotate-180;
      }
    }
  }
}
