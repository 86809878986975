@tailwind components;

:root {
  --slide-animation-duration: 6s;
  --rotate-animation-duration: 5s;

  --text-position: 0px;
  --middle-text-position: -85px;

  --animation-delay: 0s;
}

@layer components {
  @keyframes rotation {
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes slideFromRightToLeft {
    0% {
      opacity: 0;
      transform: translateX(200px);
    }
    20%,
    80% {
      opacity: 1;
      transform: translateX(var(--text-position));
    }
    100% {
      opacity: 0;
      transform: translateX(-200px);
    }
  }

  @keyframes slideFromLeftToRight {
    0% {
      opacity: 0;
      transform: translateX(-200px);
    }
    20%,
    80% {
      opacity: 1;
      transform: translateX(var(--middle-text-position));
    }
    100% {
      opacity: 0;
      transform: translateX(200px);
    }
  }

  .splash-screen-wrapper {
    @apply pointer-events-none fixed inset-0 z-[60] flex overflow-hidden transition-opacity duration-1000;

    &:after {
      content: '';
      @apply absolute bg-radial-gradient;
      animation: rotation var(--rotate-animation-duration) linear infinite;
      height: 150vw;
      width: 150vw;
      left: -30vw;
      top: -30vw;
    }

    .text-container {
      @apply z-[52] flex h-screen w-screen flex-col items-center justify-center gap-3;

      h1:nth-child(1) {
        opacity: 0;
        animation: slideFromRightToLeft var(--slide-animation-duration) ease-in-out
          var(--animation-delay);
      }

      h1:nth-child(2) {
        --animation-delay: 0.3s;
        opacity: 0;
        transform: translateX(var(--middle-text-position));
        animation: slideFromLeftToRight var(--slide-animation-duration) ease-in-out
          var(--animation-delay);
      }

      h1:nth-child(3) {
        --animation-delay: 0.5s;
        --text-position: 80px;
        opacity: 0;
        transform: translateX(var(--bottom-text-position));
        animation: slideFromRightToLeft var(--slide-animation-duration) ease-in-out
          var(--animation-delay);
      }
    }
  }
}
