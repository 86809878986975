@tailwind components;

@layer components {
  #root .sentiment-progress-wrapper {
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
    justify-content: center;
    align-items: center;

    max-height: 20px;
    min-height: 20px;
  }

  .negative-sentiment-wrapper {
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
    align-items: center;

    max-height: 20px;
    min-height: 20px;

    border-right: 1px dashed #000000;
  }

  .negative-sentiment-wrapper progress::-webkit-progress-bar {
    background: #f2f2f2;
    border-radius: 0 10px 10px 0;
  }

  .negative-sentiment-wrapper progress::-webkit-progress-value {
    @apply bg-nano-light-purple;
    border-radius: 0 10px 10px 0;
  }

  .negative-sentiment-wrapper progress {
    height: 8px;
    max-width: 100%;
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    transform: rotate(180deg);
  }

  .positive-sentiment-wrapper {
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
    align-items: center;

    max-height: 20px;
    min-height: 20px;
  }

  .positive-sentiment-wrapper progress::-webkit-progress-bar {
    background: #f2f2f2;
    border-radius: 0 10px 10px 0;
  }

  .positive-sentiment-wrapper progress::-webkit-progress-value {
    @apply bg-nano-pca-teal;
    border-radius: 0 10px 10px 0;
  }

  .positive-sentiment-wrapper progress {
    height: 8px;
    max-width: 100%;
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
  }

  .grayed-sentiment progress::-webkit-progress-value {
    background-color: #bdbdbd;
  }
}
