@tailwind components;

@layer components {
  @keyframes loadingFade {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0;
    }
  }

  .typing {
    @apply flex h-14 w-24 items-center justify-center bg-primary-dark-purple-80;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    border-top-right-radius: 24px;

    .dot {
      @apply mx-1 h-2 w-2 rounded-full bg-text-40 opacity-0;
      animation: loadingFade 1s infinite;

      &:nth-child(1) {
        animation-delay: 0s;
      }

      &:nth-child(2) {
        animation-delay: 0.2s;
      }

      &:nth-child(3) {
        animation-delay: 0.4s;
      }
    }
  }
}
