@import './assets/fonts/fonts.scss';
@import 'components/components.scss';
@import 'modules/modules.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body * {
    font-family:
      Euclid Circular A,
      sans-serif !important;
    font-size: theme('fontSize.base');

    scrollbar-width: thin; /* Firefox and Chrome*/
    scrollbar-color: #806883 transparent; /* Firefox and Chrome*/
    @apply subpixel-antialiased;
  }

  #root {
    min-height: 100vh;
  }

  .page-background {
    @apply bg-page-default bg-main bg-contain bg-[top_0_right_0] bg-no-repeat;
  }

  .page-pattern-background {
    @apply bg-pattern bg-auto bg-repeat;
  }

  .unauthorized-background {
    @apply bg-unauthorizedLeftBg bg-contain bg-left bg-no-repeat;
  }

  .nano-progress {
    &.ant-progress.ant-progress-circle {
      .ant-progress-text {
        @apply text-heading-s font-semibold text-primary-dark-purple-100;
      }
    }
  }

  .nano-tiv-metrics {
    background-image: linear-gradient(white, white),
      radial-gradient(circle at top right, rgba(1, 255, 250, 1), rgba(140, 71, 191, 0.36));
    background-clip: padding-box, border-box;
    width: 90px;
    height: 90px;
    @apply relative rounded-full border-4 border-transparent bg-origin-border;
    &:before,
    &:after {
      width: 18px;
      height: 4px;
      content: '';
      @apply absolute top-0 rounded-lg bg-nano-electric-cyan;
    }
    &:before {
      left: -5px;
      @apply -rotate-45;
    }
    &:after {
      right: -5px;
      @apply rotate-45;
    }
  }
}

.nano-attention-metrics {
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top right, rgba(1, 255, 250, 1), rgba(140, 71, 191, 0.36));
  background-clip: padding-box, border-box;
  width: 90px;
  height: 90px;
  @apply relative rounded-full border-4 border-transparent bg-origin-border;
  &:before,
  &:after {
    width: 18px;
    height: 4px;
    content: '';
    @apply absolute top-0 rounded-lg;
  }
}

@layer components {
  a,
  .link-element {
    @apply underline underline-offset-4 transition-colors ease-in-out hover:text-secondary-purple active:text-primary-dark-purple-80;
  }
}

/* Override the track color */
.ant-slider-track,
.ant-slider:hover .ant-slider-track {
  background-color: purple !important; /* Example color */
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #806883;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}
