@tailwind components;

@layer components {
  #root .nano-chip {
    @apply rounded-2xl bg-primary-dark-purple-5 px-4 py-2 leading-4 text-primary-dark-purple-100 hover:bg-primary-dark-purple-20;
    &.ant-tag-checkable-checked {
      @apply bg-primary-dark-purple-100 text-secondary-white;
    }
  }
}
