@tailwind components;

@layer components {
  .nano-file-upload {
    @apply flex cursor-pointer items-center justify-center rounded-xl border-[1px] border-dashed border-primary-dark-purple-40 bg-inherit p-4 text-primary-dark-purple-40 transition-all hover:border-secondary-purple;
    input {
      @apply invisible h-0 w-0;
    }
  }
}
