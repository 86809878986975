@tailwind components;

@layer components {
  .input {
    @apply relative h-[42px] rounded-lg border-transparent bg-primary-dark-purple-5 px-3.5 text-base-l text-text-100 placeholder:text-text-60 hover:z-auto hover:border-primary-dark-purple-60 focus:z-auto focus:border-system-info-100 focus:shadow-input-focus-shadow focus:outline-0;
    &:last-child {
      @apply rounded-lg;
    }
  }

  .nano-input.ant-input {
    @apply input;
  }

  .nano-input.ant-input-group-wrapper input {
    @apply input pl-10;
  }

  .nano-input.ant-input-password {
    @apply border-transparent bg-transparent p-0;
  }

  .nano-input.ant-input-password input {
    @apply input pr-10;
  }

  .nano-input.nano-input__large,
  .nano-input.nano-input__large.ant-input-group-wrapper input,
  .nano-input.nano-input__large.ant-input-password input {
    height: 52px;
  }

  .nano-input.nano-input__invalid,
  .nano-input.nano-input__invalid.ant-input-group-wrapper input,
  .nano-input.nano-input__invalid.ant-input-password input {
    @apply border-system-error-100 text-system-error-100 placeholder:text-system-error-50 focus:border-system-error-100 focus:shadow-input-error-shadow;
  }

  .nano-input.ant-input-group-wrapper .ant-input-group-addon {
    @apply absolute left-[12px] z-10 flex h-full w-[16px] items-center border-0 bg-transparent p-0;
  }

  .nano-input.ant-input-password .ant-input-suffix {
    @apply absolute right-[12px] z-10 flex h-full w-[16px] items-center border-0 bg-transparent p-0;
  }

  .nano-input.ant-input-group-wrapper .ant-input-group-addon > * {
    @apply w-full text-text-60;
  }
}
