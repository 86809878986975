@tailwind components;

@layer components {
  .nano-notification {
    &.ant-notification-notice-error {
      @apply bg-system-error-active;
    }

    &.ant-notification-notice-info {
      @apply bg-primary-dark-purple-100;
    }

    &.ant-notification-notice-error,
    &.ant-notification-notice-info {
      .ant-notification-notice-message {
        @apply hidden;
      }

      .ant-notification-notice-description {
        @apply pr-4 text-secondary-white;
        margin-inline-start: 0 !important;
      }

      .ant-notification-notice-icon {
        @apply hidden;
      }

      .ant-notification-notice-close {
        @apply text-secondary-white hover:text-secondary-white;
      }
    }
  }
}
