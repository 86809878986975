@tailwind components;

@layer components {
  #root {
    .nano-carousel {
      .slick-slide {
        &.slick-current {
          width: auto !important;
          @apply mr-10;
        }
      }
    }
  }
}
