@tailwind components;

@layer components {
  #root .nano-checkbox {
    @apply gap-x-3;

    .ant-checkbox-inner {
      width: 20px;
      height: 20px;
      border-radius: 3px;
      background-color: transparent;
      border: 2px solid theme('colors.text.20');

      &:after {
        top: 50%;
        left: 29%;
      }
    }

    .ant-checkbox-input {
      &:focus-visible + .ant-checkbox-inner {
        outline: 2px solid theme('colors.system.info.100');
        outline-offset: 2px;
      }
    }

    span {
      &:last-child {
        font-size: theme('fontSize.base-l');
        color: theme('colors.text.80');
        padding: 0;
      }
    }

    &:hover {
      span:last-child {
        color: theme('colors.text.100');
      }

      .ant-checkbox-inner {
        border-color: theme('colors.secondary.purple');
      }
    }

    &:active {
      .ant-checkbox-inner {
        background-color: theme('colors.primary.electric-cyan.100');
      }
    }

    &:not(.ant-checkbox-wrapper-disabled) {
      .ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: theme('colors.primary.dark-purple.80');
          border-color: theme('colors.primary.dark-purple.80');
        }
      }

      &:hover {
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: theme('colors.secondary.purple');
            border-color: theme('colors.secondary.purple');
          }
        }
      }
    }

    &.ant-checkbox-wrapper-disabled {
      .ant-checkbox-inner {
        background-color: theme('colors.text.10');
        border-color: theme('colors.text.10');

        &:after {
          border-color: theme('colors.secondary.white');
        }
      }

      span {
        &:last-child {
          color: theme('colors.text.40');
        }
      }
    }
  }
}
