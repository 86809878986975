@tailwind components;

@layer components {
  .nano-uploader.ant-upload-wrapper {
    .ant-upload.ant-upload-drag {
      @apply border-dashed bg-inherit text-primary-dark-purple-40;
    }

    .ant-upload-list {
      @apply flex flex-col gap-2;

      .ant-upload-list-item-container {
        @apply h-[75px] bg-secondary-mint px-3 pb-3 pl-8;
      }
    }
  }
}
