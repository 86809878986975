@tailwind components;

@layer components {
  .nano-select.ant-select {
    @apply h-[42px] w-full;
  }
  .nano-select.ant-select.bg-white > .ant-select-selector {
    @apply bg-secondary-white;
  }
  .nano-select.ant-select > .ant-select-selector {
    @apply h-[42px] w-full rounded-lg border-transparent bg-primary-dark-purple-5 text-base-l text-text-100 hover:z-auto hover:border-primary-dark-purple-60;
  }

  .nano-select.ant-select > .ant-select-selector > .ant-select-selection-placeholder {
    @apply py-[5px] text-base-l text-text-60;
  }

  .nano-select.ant-select > .ant-select-selector > .ant-select-selection-item {
    @apply py-[5px] text-base-l text-text-60;
  }

  .nano-select.ant-select
    > .ant-select-selector
    > .ant-select-selection-search
    > .ant-select-selection-search-input {
    @apply h-[42px] w-full;
  }

  .nano-select.ant-select > .ant-select-arrow {
    @apply text-text-80;
  }

  .nano-select.nano-select__full-width {
    @apply w-full;
  }

  .nano-select.nano-select__full-width > .ant-select-selector {
    @apply w-full;
  }

  .nano-select.ant-select.ant-select-open > .ant-select-selector {
    @apply z-auto border-system-info-100 shadow-input-focus-shadow;
  }

  .nano-select.nano-select__large {
    height: 52px;
  }

  .nano-select.nano-select__large > .ant-select-selector {
    height: 52px;
  }

  .nano-select.nano-select__large > .ant-select-selector > .ant-select-selection-placeholder {
    @apply py-3 text-base-l text-text-60;
  }

  .nano-select.nano-select__large > .ant-select-selector > .ant-select-selection-item {
    @apply py-3 text-base-l text-text-60;
  }

  .nano-select.nano-select__invalid > .ant-select-selector,
  .nano-select.nano-select__invalid > .ant-select-selector > .ant-select-selection-placeholder {
    @apply border-system-error-100 text-system-error-100 placeholder:text-system-error-50 focus:border-system-error-100 focus:shadow-input-error-shadow;
  }

  .nano-select-dropdown.ant-select-dropdown .ant-select-item-option {
    @apply h-[38px] px-4 py-2;
  }

  .nano-select-dropdown.ant-select-dropdown .ant-select-item-option-selected {
    @apply bg-primary-dark-purple-5 text-base-l font-normal text-text-100;
  }

  .nano-select-dropdown.ant-select-dropdown .ant-select-item-option-disabled {
    @apply bg-primary-dark-purple-5 font-normal text-text-40;
  }
}
