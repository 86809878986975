@font-face {
  font-family: 'Euclid Circular A';
  src: url('./Euclid Circular A Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('./Euclid Circular A Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('./Euclid Circular A Bold Italic.ttf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('./Euclid Circular A Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('./Euclid Circular A Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('./Euclid Circular A Light Italic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('./Euclid Circular A Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('./Euclid Circular A Medium Italic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('./Euclid Circular A SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('./Euclid Circular A SemiBold Italic.ttf');
  font-weight: 600;
  font-style: italic;
}
