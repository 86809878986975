@tailwind components;

@layer components {
  #root .nano-collapse {
    @apply border-0 bg-transparent;
    .ant-collapse-item {
      @apply mb-5 w-full rounded-t-lg border-b-0 bg-secondary-white py-3.5 pl-6 pr-4 last-of-type:mb-0;
      .ant-collapse-expand-icon {
        @apply m-0 p-0;
      }

      .ant-collapse-header {
        @apply p-0;
        .ant-collapse-header-text {
          @apply text-base-l font-medium;
        }
      }

      .ant-collapse-content {
        @apply border-t-0;
        .ant-collapse-content-box {
          @apply px-0 py-4;
        }
      }

      &.ant-collapse-item-active {
        .ant-collapse-header-text {
          @apply text-secondary-purple;
        }

        .ant-collapse-expand-icon {
          @apply rotate-180;
        }
      }
    }
  }
}
